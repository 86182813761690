import {
  AuviousRtcService,
  ConversationChannelEnum,
  ConversationOriginEnum,
  ConversationTypeEnum,
  GenericErrorHandler,
  IConversationEventHandlers,
  ITranscript,
  ProtectedTicketService,
  RecorderService,
  UserService,
} from "../../../../core-ui";
import {
  IConversationBase,
  IInteraction,
  IInteractionDetails,
  IInteractionMessageOptions,
} from "../../../../core-ui/models/IInteraction";
import { IInteractionStrategy } from "../../../../core-ui/models/strategies";
import { ApiResource, Event } from "@auvious/common";
import {
  IDCStartedEvent,
  DCEventTypeEnum,
  IDCEvent,
  IDCEndingEvent,
  IDCMessageHistoryResponse,
} from "@auvious/integrations";
import { debugError } from "../../../../app/app.utils";
import {
  DigitalConnectInteraction,
  IDigitalConnectInteraction,
} from "../../DigitalConnectInteraction";
import { IntegrationService } from "../../../services";
import { firstValueFrom } from "rxjs";
import { PARAM_CALL_TYPE } from "../../../app.enums";

export class TalkdeskInteractionStrategy implements IInteractionStrategy {
  private conversationResource: ApiResource;
  private activeInteraction: IInteraction;
  private handlers: IConversationEventHandlers;

  constructor(
    private userService: UserService,
    private rtcService: AuviousRtcService,
    private integration: IntegrationService,
    private ticketService: ProtectedTicketService,
    private recorderService: RecorderService,
    private errorHandler: GenericErrorHandler
  ) {
    this.rtcService.common$.subscribe((common) => {
      this.conversationResource = common.apiResourceFactory(
        "api/digital-connect/talkdesk/conversations"
      );
    });
    // setup event listeners
    firstValueFrom(this.rtcService.getEventObservableAvailable()).then(
      (eventObservable) => {
        eventObservable.subscribe(this.handleEvent.bind(this));
      }
    );
  }

  isActiveInteractionAvailable(): boolean {
    return !!this.activeInteraction;
  }
  getActiveInteraction(): IInteraction {
    return this.activeInteraction;
  }
  setActiveInteraction(interaction: IInteraction) {
    this.activeInteraction = interaction;
  }
  clearActiveInteraction() {
    this.activeInteraction = null;
  }
  async discoverActiveInteraction(): Promise<IInteraction> {
    try {
      const conversations = await this.getActiveConversations();
      let activeConversation: IDigitalConnectInteraction;
      if (conversations?.length === 1) {
        activeConversation = conversations[0];
      } else if (conversations?.length > 1) {
        // find most recent conversation
        const cd = conversations.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );
        activeConversation = cd[0];
      } else {
        return null;
      }

      const interaction = await this.retrieveInteractionData(
        activeConversation.interactionId
      );
      this.setActiveInteraction(interaction);
      return interaction;
    } catch (ex) {
      debugError(ex);
    }
    return null;
  }

  async retrieveInteractionData(id: string) {
    try {
      const conversation = await this.getTalkdeskInteraction(id);
      return new DigitalConnectInteraction(conversation);
    } catch (ex) {
      debugError(ex);
    }
    return null;
  }
  registerConversationEventHandlers(handlers: IConversationEventHandlers) {
    this.handlers = handlers;
  }

  prepareInteractionScheduleRequest() {
    return null;
  }
  scheduleInteraction() {
    return null;
  }
  createEmailInteraction() {
    return null;
  }

  invite(
    toConversationType: ConversationTypeEnum,
    interaction: IInteraction,
    ticket: string,
    options?: IInteractionMessageOptions
  ) {
    switch (toConversationType) {
      case ConversationTypeEnum.cobrowse:
        return this.cobrowseRequested(
          interaction.getIntegrationId(),
          ticket,
          options.requestType
        );
      case ConversationTypeEnum.chat:
      case ConversationTypeEnum.videoCall:
      case ConversationTypeEnum.voiceCall:
        // const url = this.ticketService.getTicketWithDomain(ticket);
        let link: URL | string = "";
        const customerLink = this.ticketService.getTicketWithDomain(ticket);
        try {
          link = new URL(customerLink);
          link.searchParams.set(PARAM_CALL_TYPE, interaction.getType());
        } catch (ex) {
          // this.errorHandler.handleError(ex.message + " -> " + customerLink);
          link =
            customerLink.indexOf("?") > -1
              ? `${link}&${PARAM_CALL_TYPE}=${interaction.getType()}`
              : `${link}?${PARAM_CALL_TYPE}=${interaction.getType()}`;
        }
        const url = link.toString();
        return this.callCreated(interaction.getIntegrationId(), url);
    }
  }

  getInvitation(
    forConversationType: ConversationTypeEnum,
    ticket: string,
    options?: IInteractionMessageOptions
  ): string {
    return null;
  }

  postMessageToInteraction(
    interaction: IInteraction,
    message: string,
    options?: IInteractionMessageOptions
  ): Promise<void> {
    return null;
  }
  createSMSInteraction() {
    return null;
  }
  async getChatTranscript(
    interactionId: string,
    channel: ConversationChannelEnum,
    fromStorageProvider: boolean
  ): Promise<ITranscript> {
    let transcript = {
      chatTranscriptList: [],
      conversationId: interactionId,
      instanceId: undefined,
      recorderId: undefined,
    };
    try {
      if (fromStorageProvider) {
        transcript = await this.recorderService.getTranscript(interactionId);
      } else {
        const chatHistory: IDCMessageHistoryResponse[] =
          await this.conversationResource
            .get({
              urlPostfix: interactionId + "/messages",
            })
            .catch((ex) => {
              this.errorHandler.handleNotAuthenticatedError(ex);
            });

        transcript.chatTranscriptList = chatHistory.map((message) => ({
          id: message.messageId,
          timestamp: message.created,
          transcript: message.content,
          userDisplayName: message.author,
          userId: message.name,
        }));
      }
      return transcript;
    } catch (ex) {
      return transcript;
    }
  }
  getInteractionDetails(): Promise<IInteractionDetails> {
    return null;
  }

  /** private methods */

  private handleEvent(event: Event) {
    let typedPayload;

    // interactionId: talkdesk interaction id
    // conversationId: Auvious digital connect id

    switch (event.payload.type) {
      case DCEventTypeEnum.started:
        typedPayload = event.payload as IDCStartedEvent;
        const conv: IConversationBase = {
          conversationId: typedPayload.interactionId,
          room: null,
          origin: ConversationOriginEnum.WIDGET,
          type: typedPayload.properties
            ?.conversationType as ConversationTypeEnum,
          channel: ConversationChannelEnum.digitalConnect,
        };
        this.handlers?.started(conv);
        break;
      case DCEventTypeEnum.ending:
        this.handlers?.ending((event.payload as IDCEndingEvent).interactionId);
        break;
      case DCEventTypeEnum.ended:
        this.handlers?.ended((event.payload as IDCEvent).interactionId);
        break;
      case DCEventTypeEnum.messageReceived:
        break;
    }
  }

  private async getActiveConversations(): Promise<
    IDigitalConnectInteraction[]
  > {
    return this.conversationResource
      .get({
        urlPostfix: "active",
        params: {
          agentId: this.integration.getIntegrationUserId(
            this.userService.getActiveUser()
          ),
        },
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   *
   * @param id auvious digital connect id
   * @returns DigitalConnectInteraction
   */
  private async getDigitalConnectConversation(
    id: string
  ): Promise<IDigitalConnectInteraction> {
    return this.conversationResource
      .get({
        urlPostfix: id,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   *
   * @param id talkdesk interaction id
   * @returns DigitalConnectInteraction
   */
  private async getTalkdeskInteraction(
    id: string
  ): Promise<IDigitalConnectInteraction> {
    return this.conversationResource
      .get({
        urlPostfix: `interaction/${id}`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   *
   * @param interactionId talkdesk interaction id
   * @param url
   * @returns
   */
  private callCreated(interactionId, url) {
    return this.conversationResource
      .create(
        {
          interactionId,
          url,
        },
        {
          urlPostfix: "call",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   *
   * @param interactionId talkdesk interaction id
   * @param ticket
   * @returns
   */
  private cobrowseRequested(interactionId, ticket, requestType) {
    return this.conversationResource
      .create(
        {
          interactionId,
          ticket,
          requestType,
        },
        {
          urlPostfix: "cobrowse",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  // Talkdesk does not have an API (for now) to send messages as agent
  // private sendConversationMessage(conversationId: string, content: string){
  //   return this.apiResource.create(
  //     {
  //       conversationId,
  //       content,
  //     },
  //     {
  //       urlPostfix: "messages",
  //     }
  //   );
  // }
}
