<div
  role="heading"
  class="panel-header-title"
  [class.panel-header-title-close]="isCloseVisible"
>
  <ng-content></ng-content>
</div>
<div *ngIf="!!subtitle" class="panel-header-subtitle">
  {{ subtitle | translate }}
</div>
<button
  avButton
  ofType="icon"
  size=""
  color="link"
  [attr.aria-label]="'Close card' | translate"
  *ngIf="isCloseVisible"
  (click)="cancel()"
  data-tid="card-header/cancel"
  class="btn-close btn-close-float"
>
  <av-icon name="close"> </av-icon>
</button>
<div class="card-header-actions">
  <ng-content select="[card-header-actions]"></ng-content>
</div>
