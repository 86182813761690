import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "av-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmComponent implements OnInit {
  @Input() open = false;
  @Input() arrow: "top-left" | "top-right" | "bottom-left" | "bottom-right" =
    "top-left";

  @Input() cancelLabel: string;

  @Output() confirmed: EventEmitter<void> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  hAlign: string;
  vAlign: string;

  constructor() {}

  ngOnInit(): void {
    this.hAlign = this.arrow.split("-")?.[1] || "left";
    this.vAlign = this.arrow.split("-")?.[0] === "bottom" ? "top" : "bottom";
  }

  // get hAlign() {
  //   return  // === "top-left" ? "left" : "right";
  // }
  // get vAlign() {
  //   return this.arrow.split("-")?.[1] || "top";
  // }

  cancel() {
    this.canceled.emit();
  }

  confirm() {
    this.confirmed.emit();
  }
}
