import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { KEY_LANG, KEY_LANG_COUNTRY } from "../app.enums";
import { AppConfigService } from "../../core-ui/services/app.config.service";
import * as moment from "moment";
import { localStore } from "@auvious/utils";
import { StorageAssetService } from "../../core-ui/services/storage.asset.service";
import { firstValueFrom } from "rxjs";
import { AssetCategoryEnum } from "../../core-ui";
import { Asset } from "@auvious/asset";
import { HttpClient } from "@angular/common/http";
import { ILocaleEntity } from "../../core-ui/models/interfaces";
import { LOCALES } from "../../core-ui/core-ui.enums";

//as found in country-dial-codes.js
export interface IDialCode {
  name: string;
  dial_code: string;
  code: string;
}

// as found in locales.json
interface ILocales {
  languageNames: {
    [key: string]: string[];
  };
  primaryDialects: {
    [key: string]: string;
  };
}
@Injectable()
export class LocaleService {
  private loaded = false;
  private locales: ILocaleEntity[];
  private countryCode: string;
  private dialCodes: IDialCode[] = [];

  constructor(
    private translate: TranslateService,
    private config: AppConfigService,
    private storage: StorageAssetService,
    private httpClient: HttpClient
  ) {}

  public init() {
    const lang = this.discoverLang();

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang("en");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(lang);

    moment.locale(lang);
  }

  async loadInstanceTranslationsForLocale() {
    if (this.loaded) {
      return;
    }
    const lang = this.discoverLang();
    try {
      const files = (await this.storage.getAssets()).filter(
        (a) =>
          a.category === AssetCategoryEnum.i18n &&
          (a as Asset).filename === `${lang}.json`
      );
      if (!files.length) {
        return;
      }
      const file = files.pop();

      const url = await firstValueFrom(file.signedUrl);

      const translations = await firstValueFrom(this.httpClient.get(url));

      this.translate.setTranslation(lang, translations, false);
    } catch (ex) {
    } finally {
      this.loaded = true;
    }
  }

  public getLocales() {
    if (!this.locales) {
      const data: ILocales = LOCALES;
      // (await firstValueFrom(
      //   this.httpClient.get("/assets/locales.json")
      // )) as ILocales;
      this.locales = [];
      Object.keys(data.primaryDialects).forEach((key) => {
        this.locales.push({
          locale: data.primaryDialects[key],
          language: key,
          name: data.languageNames[data.primaryDialects[key]][1],
          localisedName: data.languageNames[data.primaryDialects[key]][0],
        });
      });
    }
    return this.locales;
  }

  public getCountryDialCodes(): Promise<IDialCode[]> {
    return new Promise((resolve, reject) => {
      if (this.dialCodes.length > 0) {
        return resolve(this.dialCodes);
      }
      this.httpClient.get("/assets/country-dial-codes.json").subscribe({
        next: (response: []) => {
          this.dialCodes = response;
          resolve(this.dialCodes);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  public getLocale(): ILocaleEntity {
    const lang = this.discoverLang();
    const locales = this.getLocales();
    return locales.find((l) => l.language === lang);
  }

  public getCountryCode(): string {
    return this.countryCode ?? "US";
  }

  public getLanguage(): string {
    return this.discoverLang();
  }

  private discoverLang(): string {
    let langParam;
    let locale = this.getQueryVariable("lang");
    if (locale) {
      if (locale.split("-").length > 1) {
        const langLocal = locale.split("-")[0];
        // if (this.config.supportedLanguages.includes(langLocal)) {
        // query param lang is a supported language
        langParam = langLocal;
        localStore.setItem(KEY_LANG, langParam);
        // }
        this.countryCode = locale.split("-")[1];
        localStore.setItem(KEY_LANG_COUNTRY, this.countryCode);
      }
    } else {
      langParam = localStore.getItem(KEY_LANG);
      if (!langParam) {
        /*
        let browserLang = navigator.language;
        if (!!browserLang) {
          // lang is in iso format (en-US)
          if (browserLang.split("-").length > 1) {
            browserLang = browserLang.split("-")[0].toLowerCase();
          }
          if (this.config.supportedLanguages.includes(browserLang)) {
            // browser lang is a supported language
            langParam = browserLang;
          }
        } 
        */
        const browserLang = this.translate.getBrowserLang().toLowerCase();
        // if (this.config.supportedLanguages.includes(browserLang)) {
        // browser lang is a supported language
        langParam = browserLang;
        // }
      }
      this.countryCode = localStore.getItem(KEY_LANG_COUNTRY);
      if (!this.countryCode) {
        // get country code
        locale = this.translate.getBrowserCultureLang();
        if (locale?.split("-").length > 1) {
          this.countryCode = locale.split("-")[1];
        }
      }
    }
    const lang = langParam || "en";
    return lang;
  }

  private getQueryVariable(queryParam: string): string {
    const usp = new URLSearchParams(window.location.search);
    return usp.has(queryParam) ? decodeURIComponent(usp.get(queryParam)) : null;
  }
}
