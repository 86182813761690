<div class="controls-container">
  <div role="group" class="panel-body-row">
    <label for="change-audio-input">
      <b translate>Audio Input</b>
    </label>
    <select
      name="mic"
      [disabled]="isAudioDisabled"
      [(ngModel)]="audioDeviceId"
      (ngModelChange)="deviceChange('audioinput', $event)"
      id="change-audio-input"
      data-tid="device-settings/change-audio-input"
    >
      <option
        *ngFor="let device of audioMediaDevices; trackBy: trackByFn"
        [value]="device.deviceId"
      >
        {{ device.label }}
      </option>
    </select>
  </div>

  <div role="group" class="panel-body-row" *ngIf="videoMediaDevices.length > 0">
    <label for="change-video-input">
      <b translate>Camera Input</b>
    </label>
    <select
      name="cam"
      [disabled]="!camOn"
      [(ngModel)]="videoDeviceId"
      (ngModelChange)="deviceChange('videoinput', $event)"
      id="change-video-input"
      data-tid="device-settings/change-video-input"
    >
      <option
        *ngFor="let device of videoMediaDevices; trackBy: trackByFn"
        [value]="device.deviceId"
      >
        {{ device.label }}
      </option>
    </select>
  </div>

  <div role="group" class="panel-body-row" *ngIf="isSpeakerSupported">
    <label for="change-audio-output">
      <b translate>Audio Output</b>
    </label>
    <select
      name="speaker"
      [disabled]="isSpeakerDisabled"
      [(ngModel)]="speakerDeviceId"
      (ngModelChange)="deviceChange('audiooutput', $event)"
      id="change-audio-output"
      data-tid="device-settings/change-audio-output"
    >
      <option
        *ngFor="let device of speakerMediaDevices; trackBy: trackByFn"
        [value]="device.deviceId"
      >
        {{ device.label }}
      </option>
    </select>
  </div>
</div>
