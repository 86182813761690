<ng-content></ng-content>

<av-card
  *ngIf="open"
  [popup]="true"
  [arrow]="arrow"
  [backdrop]="false"
  [hAlign]="hAlign"
  [vAlign]="vAlign"
  aria-labelledby="confirm-dialog-header"
  aria-describedby="confirm-dialog-body"
>
  <av-card-header id="confirm-dialog-header">
    <ng-content select="[confirmTitle]"></ng-content>
  </av-card-header>
  <av-card-body id="confirm-dialog-body">
    <ng-content select="[confirmBody]"></ng-content>
  </av-card-body>
  <av-card-footer>
    <div class="fx-row fx-align-center fx-justify-end">
      <button
        [attr.aria-label]="'Cancel' | translate"
        avButton
        (click)="cancel()"
        translate
        data-tid="confirm/cancel"
      >
        {{ cancelLabel || "Cancel" }}
      </button>
      <button
        [attr.aria-label]="'Confirm' | translate"
        avButton
        color="accent"
        (click)="confirm()"
        translate
        data-tid="confirm/yes"
      >
        Yes
      </button>
    </div>
  </av-card-footer>
</av-card>
