import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostBinding,
  HostListener,
  Optional,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  AfterViewInit,
} from "@angular/core";
import { CardComponent } from "../card/card.component";

@Component({
  selector: "av-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent implements OnInit, AfterViewInit {
  @Input() icon: string;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    this.host.nativeElement.setAttribute("aria-disabled", String(value));
  }
  get disabled(): boolean {
    return this._disabled;
  }
  @Input() color: "danger" | "success";
  @Input() arrow: boolean;
  @Input() arrowType: "toggle" | "submenu" = "submenu";
  @Input() expanded: boolean;
  @Input() active: boolean;
  @Input()
  set checked(value: boolean) {
    this._checked = value;
    this.host.nativeElement.setAttribute("aria-checked", String(value));
  }
  get checked(): boolean {
    return this._checked;
  }
  @Input() type: "header" | "separator" | "item" | "sub-item" | "radio";
  @Input() info: string;
  @Input() postfix: string;
  @Input() autoSubMenuPosition: boolean;
  @Input() loading: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select: EventEmitter<void> = new EventEmitter();

  private _disabled: boolean;
  private _checked: boolean;

  isInCard: boolean;
  isItem: boolean;
  isHeader: boolean;

  @HostBinding("class") get class() {
    const cl = {
      "menu-item": this.type === "item" || this.type === "radio" || !this.type,
      "menu-item-separator": this.type === "separator",
      "menu-item-header": this.type === "header",
      "menu-sub-item": this.type === "sub-item",
      "menu-item-disabled": this.disabled,
      "menu-item-danger": this.color === "danger",
      "menu-item-success": this.color === "success",
      "menu-item-active": this.active,
      "menu-item-chevron-toggle": this.arrowType === "toggle",
      "menu-item-expanded": this.expanded,
      "menu-item-checked": this.checked,
      "menu-item-has-info": !!this.info,
      "menu-item-in-card": this.isInCard,
      "menu-item-loading": this.loading,
    };
    return cl;
  }

  @ViewChild("label") labelRef: ElementRef<HTMLDivElement>;
  @ViewChild("arrow") arrowRef: ElementRef<HTMLDivElement>;

  @HostListener("click", ["$event"])
  onClick(e) {
    if (!this.disabled) {
      this.select.emit();
    }
  }

  constructor(
    private host: ElementRef<HTMLDivElement>,
    @Optional() private card: CardComponent
  ) {}

  get isSubMenuOutOfWindow() {
    if (!this.autoSubMenuPosition) {
      return;
    }
    const myRect = this.host.nativeElement.getBoundingClientRect();
    return window.innerWidth < myRect.width + myRect.left + 200; // 200 is the miminum subMenu width
  }

  ngOnInit() {
    let role;
    switch (this.type) {
      case "separator":
        role = "separator";
        break;
      case "header":
        role = "header";
        break;
      case "radio":
        role = "menuitemradio";
        break;
      case "item":
      case "sub-item":
      default:
        role = "menuitem";
        break;
    }
    this.host.nativeElement.setAttribute("role", role);

    if (this.arrow) {
      this.host.nativeElement.setAttribute("aria-haspopup", "menu");
    }

    this.isInCard = !!this.card;
    this.isItem = this.type === "item" || this.type === "radio" || !this.type;
    this.isHeader = this.type === "header";
  }

  ngAfterViewInit() {
    if (this.active && (this.isItem || this.type === "sub-item")) {
      this.host.nativeElement.focus();
    }
  }

  @HostBinding("attr.tabindex") get tabindex(): string | null {
    return this.isItem || this.type === "sub-item" ? "0" : null;
  }
}
