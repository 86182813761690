import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener,
} from "@angular/core";

@Component({
  selector: "av-card-header",
  templateUrl: "./card-header.component.html",
  styleUrls: ["./card-header.component.scss"],
})
export class CardHeaderComponent implements OnInit {
  @Input() subtitle: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter();

  isCloseVisible = false;

  constructor() {}

  ngOnInit(): void {
    this.isCloseVisible = this.close.observers.length > 0;
  }

  @HostListener("document:keydown.escape", ["$event"]) keydown(
    e: KeyboardEvent
  ) {
    if (this.isCloseVisible) {
      this.cancel();
    }
  }

  cancel() {
    this.close.emit();
  }
}
