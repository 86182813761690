import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { StreamTypes } from "@auvious/rtc";
import { merge, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";

import { fadeInOut } from "../../core-ui.animations";
import {
  ConversationTypeEnum,
  LayoutEnum,
  TileTypeEnum,
  UserRoleEnum,
  VideoFacingModeEnum,
} from "../../core-ui.enums";
import {
  IArea,
  IStreamMetadataChangedEvent,
  ITile,
  PublicParam,
} from "../../models";
import { IInteraction } from "../../models/IInteraction";
import {
  AppConfigService,
  ArPointerActivatedEvent,
  ArPointerDeactivatedEvent,
  ConferenceService,
  ConferenceStore,
  debugError,
  DeviceService,
  EndpointState,
  LocalMediaService,
  MediaEffectsService,
  MediaRulesService,
  PointerService,
  StreamState,
  UserService,
} from "../../services";
import { NotificationService } from "../../services/notification.service";
import { BandwidthAdaptationService } from "../../services/bandwidth.adaptation.service";

const SKETCH_ACTIONS_COLUMN_LAYOUT_BREAKPOINT = 540;
const TINY_VIEW_LAYOUT_BREAKPOINT = 180;

@Component({
  selector: "app-tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
})
export class TileComponent implements OnInit, AfterViewInit, OnDestroy, ITile {
  private _stream: StreamState;

  @Input() set stream(s: StreamState) {
    this._stream = s;

    if (this.userState?.id !== s.originator.endpoint) {
      this.userState = this.store.getEndpoint(s.originator.endpoint);
    }
  }

  public get stream() {
    return this._stream;
  }

  @Input() interaction: IInteraction;

  @Input() isSpotlight: boolean;

  @Output() ready = new EventEmitter<TileComponent>();
  @Output() destroyed = new EventEmitter<TileComponent>();
  @Output() viewSizeChanged = new EventEmitter<IArea>();

  private userState: EndpointState;
  streamVolume: number;
  ArPointerOn = false;

  isFreezeFrameNotFromMute = false;
  isZoomOn = false;
  isStreamElementReady = false;
  isFilterLoading = false;
  isTinyView = false;
  isAvatarError = false;
  isSketchControlActive = false;
  isPointerActive = false;
  isPopedOut = false;
  isSketchActionsCondensed = false;

  hostSize: IArea = { width: 0, height: 0 };
  sketchId: string;
  layoutType: LayoutEnum;

  private element: HTMLVideoElement;
  private subscriptions: Subscription;
  private _ratio = 1;

  constructor(
    private host: ElementRef<HTMLDivElement>,
    private conferenceService: ConferenceService,
    private deviceService: DeviceService,
    private cd: ChangeDetectorRef,
    private mediaFilterService: MediaEffectsService,
    private notification: NotificationService,
    private zone: NgZone,
    private userService: UserService,
    private pointerService: PointerService,
    private mediaRules: MediaRulesService,
    private mediaService: LocalMediaService,
    private config: AppConfigService,
    public store: ConferenceStore,
    public bandwidthAdaptation: BandwidthAdaptationService
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {
    // this.subscriptions.add(
    //   this.conferenceService.streamMutedChange$
    //     .pipe(filter((data) => data.stream.id === this.stream.id))
    //     .subscribe((_) => {
    //       this.cd.detectChanges();
    //     })
    // );
    this.host.nativeElement.setAttribute("role", "group");
    this.host.nativeElement.setAttribute("aria-label", "Partcipant");

    this.subscriptions.add(
      merge(
        this.conferenceService.streamState$,
        this.conferenceService.endpointState$
      ).subscribe((_) => {
        this.cd.detectChanges();
      })
    );
    // this.subscriptions.add(
    //   this.conferenceService.facingModeChanged$
    //     .pipe(filter((s) => s.streamId === this._stream.id))
    //     .subscribe((_) => {
    //       this.cd.detectChanges();
    //     })
    // );

    this.subscriptions.add(
      this.mediaFilterService.filterLoading$
        .pipe(filter((_) => this.isLocal))
        .subscribe((_) => {
          this.isFilterLoading = true;
          this.cd.detectChanges();
        })
    );

    this.subscriptions.add(
      merge(
        this.mediaFilterService.filterLoaded$,
        this.mediaFilterService.filterDisabled$
      )
        .pipe(filter(() => this.isLocal))
        .subscribe(() => {
          this.isFilterLoading = false;
          this.cd.detectChanges();
        })
    );
    this.subscriptions.add(
      // take(1), not to spam ui with errors
      this.mediaFilterService.filterError$
        .pipe(
          filter(() => this.isLocal),
          take(1)
        )
        .subscribe((error) => {
          this.isFilterLoading = false;
          let body = "Could not apply effect to video.";

          switch (error) {
            case "ImageUnusable":
              body = "Could not load image";
              break;
            case "PublishFailed":
            case "UnpublishFailed":
              body = "Could not notify participants of the effect change";
              break;
            case "VideoElementNotFound":
            case "VideoTrackNotFound":
              body = "Could not find video to apply the effect";
              break;
          }

          this.notification.error("Effect failed", { body });
          this.cd.detectChanges();
        })
    );
    // this.subscriptions.add(
    //   // only for local video
    //   this.conferenceService.streamMuteWillChange$
    //     .pipe(filter((s) => s.stream.id === this.stream.id))
    //     .subscribe((data) => {
    //       this.muteChange(data.mute, data.trackKind);
    //     })
    // );
    // this.subscriptions.add(
    //   // for remote
    //   this.conferenceService.streamMutedChange$
    //     .pipe(filter((s) => s.stream.id === this.stream.id && !this.isLocal))
    //     .subscribe((data) => {
    //       this.muteChange(data.muted, data.trackKind);
    //     })
    // );
    this.subscriptions.add(
      this.pointerService.freezeFrameChange$
        .pipe(
          filter(
            (e) =>
              e.target.endpoint === this._stream.originator.endpoint &&
              e.type === this._stream.type
          )
        )
        .subscribe((e) => {
          // this.isFreezeFrameBlurred = false;
          // this.isFreezeFrameVisible = e.on;
          this.isFreezeFrameNotFromMute = e.on;
          this.cd.detectChanges();
        })
    );
    this.subscriptions.add(
      this.pointerService.zoomFrameChange$
        .pipe(
          filter(
            (e) =>
              e.target.endpoint === this._stream.originator.endpoint &&
              e.type === this._stream.type
          )
        )
        .subscribe((e) => {
          this.isZoomOn = e.on;
          this.cd.detectChanges();
        })
    );

    this.subscriptions.add(
      this.pointerService.pointAreaChange$
        .pipe(
          filter(
            (event) =>
              event.targetUserEndpointId === this.endpoint &&
              event.targetStreamType === this._stream.type
          )
        )
        .subscribe(async (event) => {
          switch (event.type) {
            case ArPointerActivatedEvent.type:
              this.isPointerActive = true;
              break;
            case ArPointerDeactivatedEvent.type:
              this.isPointerActive = false;
              break;
          }
          // wait for animation to end
          setTimeout((_) => this.cd.detectChanges(), 200);
        })
    );
    this.subscriptions.add(
      this.conferenceService.streamMetadataChanged$
        .pipe(filter((e) => e.streamId === this._stream.id))
        .subscribe((e: IStreamMetadataChangedEvent) => {
          this.ready.emit(this);
        })
    );

    // caused more trouble than what it solved
    this.subscriptions.add(
      this.mediaRules.pictureInPictureChanged$
        .pipe(
          filter((e) => e.streamId === this._stream.id)
          // delay(200)
        )
        .subscribe((e) => {
          this.isPopedOut = e.on;
          this.cd.detectChanges();
        })
    );

    this.isPointerActive = this.pointerService.isTarget(
      this.endpoint,
      this.stream.type
    );
  }

  ngAfterViewInit() {
    try {
      // if the tile is too small, the volume indicator oveflows. Listen for resizes and change class accordingly
      this.zone.runOutsideAngular(() => {
        const ro = new ResizeObserver((entries) => {
          const entry = entries?.pop();
          const size = entry?.contentRect.height <= TINY_VIEW_LAYOUT_BREAKPOINT;
          if (size !== this.isTinyView) {
            this.isTinyView = size;
          }

          this.isSketchActionsCondensed =
            entry?.contentRect.width < SKETCH_ACTIONS_COLUMN_LAYOUT_BREAKPOINT;

          this.hostSize = {
            width: entry?.contentRect.width,
            height: entry?.contentRect.height,
          };
          this.viewSizeChanged.emit(this.hostSize);
          // debug('tile resized notified', this.stream.id, this.hostSize);
          this.cd.markForCheck();
        });
        ro.observe(this.host.nativeElement);
      });
    } catch (ex) {
      debugError(ex);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.destroyed.emit(this);
  }

  @HostBinding("class") get class() {
    // debug('tile class generated', this.stream.id, this.isTinyView);
    return {
      "tile-local": this.isLocal,
      "tile-facing-user": this.isLocal && this.isFacingModeUser,
      "tile-screen": this.isScreen && !this.isPointerActive,
      "tile-cam-off": this.isMutedVideo,
      "tile-float": this.isLayoutFloating,
      "tile-small": this.isTinyView,
      "tile-portrait": !this.isScreen,
      "tile-pointer-on": this.isPointerActive,
      "tile-spotlight": this.isSpotlight,
      // 'tile-pip': this.isPopedOut,
      // [`tile-color-${this.color}`]: !!this.color
    };
  }

  // muteChange(mute: boolean, trackKind: StreamTrackKindEnum) {
  //   if (this.isFreezeFrameNotFromMute) {
  //     return;
  //   }

  //   if (mute) {
  //     this.isFreezeFrameVisible =
  //       trackKind === StreamTrackKindEnum.video || this.isMutedVideo;
  //   } else {
  //     setTimeout(() => (this.isFreezeFrameBlurred = false), 200);
  //   }

  //   this.cd.detectChanges();
  // }

  public get isFreezeFrameVisible() {
    return (
      this.isMutedVideo || this._stream.video.freeze || this.isViewerDisabled
    );
  }

  public get isFreezeFrameBlurred() {
    return this.isMutedVideo && !this._stream.video.freeze;
  }

  public get isViewerDisabled() {
    return this._stream.video.disabledFromBitrateAdaptation;
  }

  /* stream element events */

  streamElementReady(element: HTMLVideoElement) {
    this.conferenceService.bindElement(
      (this.element = element),
      this.stream.id
    );

    // TODO: some audio tracks also no canplay, what do
    // for muted streams, 'canplay' event never fires, so we need to inform manually
    if (this.isMutedVideo) {
      this._ratio = 1;
      // debug('tile:streamElementReady');
      this.ready.emit(this);
      this.isStreamElementReady = true;
      this.cd.detectChanges();
    }

    element.addEventListener("canplay", (e) => {
      if (!this._ratio) {
        this._ratio = this.element?.videoWidth / this.element?.videoHeight || 1;
      }
      // debug('tile:canPlay');
      this.ready.emit(this);
      this.isStreamElementReady = true;
      this.cd.detectChanges();
    });

    element.addEventListener("resize", (e) => {
      if (
        !this.isStreamElementReady ||
        this.element?.videoWidth === 0 ||
        this.element?.videoHeight === 0
      ) {
        return;
      }

      const ratio = this.element?.videoWidth / this.element?.videoHeight || 1;
      if (ratio !== this._ratio) {
        this._ratio = ratio;
        // debug('tile:resize');
        this.ready.emit(this);
      }
    });
  }

  streamElementDestroyed(event: {
    element: HTMLVideoElement;
    stream: MediaStream;
    id: string;
  }) {
    this.conferenceService.unbindElement(event.element, event.id);
  }

  autoplayBlocked(id: string) {
    this.conferenceService.requestExplicitPlay();
  }

  stopScreen() {
    this.mediaService.closeScreenStream();
  }

  avatarError() {
    this.isAvatarError = true;
  }

  sketchControlChanged(active: boolean) {
    this.isSketchControlActive = active;
  }

  sketchConnected(id: string) {
    this.sketchId = id;
  }

  sketchEnded(id: string) {
    this.sketchId = null;
    this.isSketchControlActive = false;
  }

  pointControlSelected() {
    this.isSketchControlActive = false;
  }

  setLayoutType(type: LayoutEnum) {
    this.layoutType = type;
  }

  /* view getters */

  get isHiddenTooSmall() {
    return (
      (this.hostSize.height < 180 || this.hostSize.width < 180) &&
      this.pointerService.isStarted &&
      !this.pointerService.isTarget(
        this._stream.originator.endpoint,
        this._stream.type
      )
    );
  }

  get isSketchAreaActive() {
    return !!this.sketchId && this.isPointerActive;
  }

  get isPortraitMode() {
    return this._stream.video.portraitMode;
  }

  get isOrientationPortrait() {
    // video is forced to be be in portrait by settings
    return (
      this.isPortraitMode ||
      // video is originally in portrait (mobile phone)
      this.element?.videoWidth < this.element?.videoHeight
    );
  }

  get avatar() {
    if (this.isAvatarError) {
      return;
    }
    if (
      this.conferenceService.myself.endpoint ===
      this._stream.originator.endpoint
    ) {
      return this.userService.getUserDetails()?.avatarUrl;
    }
    const participant =
      this.conferenceService.getParticipants()[
        this._stream.originator.endpoint
      ];
    return participant?.metadata?.avatarUrl;
  }

  get displayName() {
    if (
      this.isParticipantAgent &&
      !!(
        this.config.publicParam(PublicParam.AGENT_NAME_MASK) as string
      )?.trim() &&
      this.interaction.getType() !== ConversationTypeEnum.voiceCall
    ) {
      return this.config.publicParam(PublicParam.AGENT_NAME_MASK).trim();
    }
    return this.participant?.metadata?.name?.trim();
  }

  get isDisplayNameAvailable() {
    return (
      !!this.displayName &&
      (!this.isParticipantAgent ||
        (this.isParticipantAgent &&
          this.config.publicParam(PublicParam.AGENT_NAME_ENABLED)))
    );
  }

  get uuid() {
    return this._stream.id;
  }

  get type() {
    return this.isScreen
      ? TileTypeEnum.screenShare
      : this.ArPointerOn
      ? TileTypeEnum.arPointer
      : TileTypeEnum.video;
  }

  get isStreamPublished() {
    return "replace" in this._stream;
  }

  get ratio() {
    return this.isPortraitMode
      ? 9 / 14
      : this.element &&
        this.element.videoHeight > 0 &&
        this.element.videoWidth > 0
      ? this.element.videoWidth / this.element.videoHeight
      : this._ratio;
  }

  get isScreen() {
    return this._stream.type === StreamTypes.SCREEN;
  }

  get isVideo() {
    return (
      this._stream.type === StreamTypes.VIDEO ||
      this._stream.type === StreamTypes.CAM
    );
  }

  get container() {
    return this.host.nativeElement;
  }

  get isLayoutFloating() {
    return this.layoutType === LayoutEnum.floating;
  }

  get isFacingModeUser() {
    return this._stream.video.facingMode === VideoFacingModeEnum.User;
  }

  get isDeviceSetup() {
    return this.deviceService.isDevicesSetup;
  }

  get isEndpointSick() {
    return !this.userState || this.userState.connectivity === "unstable";
  }

  get isLocal() {
    return this._stream.originator.endpoint === this.store.endpoint;
  }

  get isMirror() {
    return this.isLocal && this.isFacingModeUser;
  }

  get isMutedAudio() {
    return (
      this._stream.audio.state === "muted" ||
      this._stream.audio.state === "disabled"
    );
  }

  get isMutedVideo() {
    return (
      this._stream.video.state === "muting" ||
      this._stream.video.state === "muted" ||
      this._stream.video.state === "disabled"
    );
  }

  get endpoint() {
    return this._stream.originator.endpoint;
  }

  get participant() {
    return this.conferenceService.getParticipants()[this.endpoint];
  }

  get isParticipantAgent() {
    return this.participant?.metadata?.roles?.includes(UserRoleEnum.agent);
  }

  get isSketchActive() {
    return this.pointerService.isTarget(this.endpoint, this._stream.type);
  }

  get isMirroringHelpActive() {
    return this.isScreen && this.streamDisplaySurface !== "window";
  }

  get isScreenHelpActive() {
    return this.isScreen && this.isLocal && !this.isPointerActive;
  }

  get streamDisplaySurface() {
    return this.stream.video.display;
  }
}
